@import url(./reset.css);
@import url(../font/pretendard/pretendard.css);

:root {
    /* Colors: */
    --unnamed-color-1c2126: #1c2126;
    --unnamed-color-2a1c19: #2a1c19;
    --unnamed-color-618ce1: #618ce1;
    --unnamed-color-3e54d3: #3e54d3;
    --unnamed-color-f2f4f8: #f2f4f8;
}

.hidden {
    display: none;
}

.contents_container_layout {
    padding: 100px 0;
    min-height: 200px;
    width: 100%;
    /* background-color: #fff; */
    box-sizing: border-box;
}

.pop_contents_container_layout {
    padding: 60px 0;
    min-height: 150px;
}

.contents_inner_container_layout {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.pop_contents_inner_container_layout {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.background-gray {
    background: #f7f7f7 0% 0% no-repeat padding-box;
}

.background-blue-w {
    background: #f6f9ff 0% 0% no-repeat padding-box;
}

.background-blue {
    background: #0938d3 0% 0% no-repeat padding-box;
}

.background-black-w {
    background: #20293a 0% 0% no-repeat padding-box;
}

.background-black-w h2,
.background-black-w h3,
.background-black-w h4,
.background-black-w p,
.background-black-w ul li {
    color: #f1f1f1 !important;
}

/* 홈화면 문의 section 배경 */
.background-ask {
    background-image: url(../../../public/img/ask-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-effect {
    background-image: url(../../../public/img/ask-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.background-reason {
    background-image: url(../../../public/img/reason_background.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
.background-analytics {
    background-image: url(../../../public/img/Analytics_back.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-dfdMainCont {
    background-image: url(../../../public/img/dfdCont_background.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}
.background-mesMainCont {
    background-image: url(../../../public/img/mes_banner.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-homeContents {
    background-image: url(../../../public/img/futuristic-presentation.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.background_company {
    background-image: url(../../../public/img/sky_3.png);

    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 360px;
}

@media screen and (max-width: 767px) {
    .contents_container_layout {
        width: 100vw;
    }
    .contents_inner_container_layout {
        width: 100%;
        padding: 0 24px;
    }
}
