.footer_container_background {
    background-color: #0e182e;
    padding: 45px 0 30px 0;
}

.footer-nav_item {
    text-align: left;
    font: normal normal 15px/18px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
    margin-right: 45px;
}

.footer-nav_item:not(:last-of-type) {
    text-decoration: none;
}

.link_color {
    color: #568cf7;
}

.footer-information {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.footer-info_cont {
    display: table-cell;
    vertical-align: bottom;
}

.left-info_cont {
    text-align: left;
    letter-spacing: 0px;
    color: #f1f1f1;
    font-size: 14px;
}

.footer-shortcut {
    margin-top: 32px;
    margin-bottom: 60px;
}

.footer_icon-item:not(:last-of-type) {
    margin-right: 16px;
}

.right-info_cont {
    text-align: right;
    letter-spacing: 0px;
    color: #eeeeee;
    font-size: 14px;
}

.info-list {
    margin-top: 30px;
}

.info-list li {
    padding-bottom: 8px;
}

.info-list strong,
.info-list h3 {
    font-weight: bold;
    font-weight: bold;
    font-size: 15px;
    margin-right: 6px;
}

.info-list h3 {
    margin-bottom: 8px;
}

.linkBtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .footer-info_cont {
        display: table-row;
    }

    .right-info_cont {
        text-align: left;
    }

    .right-info_cont h3 {
        padding-top: 40px;
    }

    .footer-nav_item {
        margin-right: 18px;
    }
}
