.contents_title {
    margin-bottom: 62px;
}
.contents_title h3 {
    color: var(--unnamed-color-3e54d3);
    text-align: center;
    font: normal bold 1.3rem/52px Pretendard;
    letter-spacing: 0px;
    color: #3e54d3;
}

.contents_title h2 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal bold 2rem/52px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.contents_title p {
    text-align: center;
    font: normal 500 1.5rem/32px Pretendard;
    letter-spacing: 0px;
    color: #464646;
    margin-top: 32px;
}

.contents-boxType {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-spacing: 15px;
}

.contents-box-item {
    display: table-cell;
    padding: 50px 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f1f1f1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contents-box-item:not(:last-of-type) {
    margin-right: 16px;
}

.contents-box-item img {
    width: 112px;
    margin-bottom: 28px;
}

.contents-box-item h3 {
    text-align: center;
    font: normal normal bold 1.3rem/32px Pretendard;
    letter-spacing: 0px;
    color: #1d1d1d;
    word-break: break-all;
    white-space: pre-line;
    margin-bottom: 28px;
}

.contents-box-item p {
    text-align: left;
    font: normal normal 1.125rem/28px Pretendard;
    letter-spacing: 0px;
    color: #464646;
    word-break: break-all;
    white-space: pre-line;
}

.contents_intro {
    margin-top: 90px;
}

.contents_intro-inner {
    display: flex;
    align-items: center;
}
.contents_intro-inner:nth-of-type(2) {
    display: flex;
    flex-direction: row-reverse;
}

.contents_intro-inner:nth-of-type(2) .intro-text {
    margin-right: 100px;
    margin-left: 0;
}

.contents_intro-inner:not(:last-of-type) {
    margin-bottom: 62px;
}

.contents_intro-inner img {
    width: 500px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.intro-text {
    margin-left: 100px;
}

.intro-text h3 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal normal 1.125rem/24px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 8px;
}

.intro-text h2 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 3rem/52px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 32px;
}
.intro-text h2 strong {
    color: var(--unnamed-color-3e54d3);
    color: #3e54d3;
}

.intro-text p {
    text-align: left;
    font: normal normal 1.125rem/28px Pretendard;
    letter-spacing: 0.4px;
    color: #464646;
    word-break: break-all;
    white-space: pre-line;
}

.background_text {
    height: 382px;
    text-align: left;
    letter-spacing: 0px;
    color: #f0f0f0;
    opacity: 0.9;
    background-image: url(../../../../public/img/background_text.png);
}

.contents-system-item {
    display: table-cell;
    padding: 30px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f1f1f1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contents-system-item:not(:last-of-type) {
    margin-right: 16px;
}

.contents-system-item img {
    width: 98px;
    height: 98px;
    margin-bottom: 14px;
}

.contents-system-item h3 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal bold 3rem/52px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    word-break: break-all;
    white-space: pre-line;
    margin-bottom: 14px;
}

.contents-system-item p {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal normal 1.125rem/32px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.readMore-btn {
    background: var(--unnamed-color-3e54d3) 0% 0% no-repeat padding-box;
    background: #3e54d3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px 36px;
    font: normal normal 600 16px/19px Pretendard;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.ask-title {
    text-align: center;
    font: normal normal bold 2rem/40px Pretendard;
    letter-spacing: 0px;
    color: #f0f0f0;
}

.ask-btn {
    background-color: transparent;
    border: 2px solid #f1f1f1;
    padding: 10px 59px;
    text-align: center;
    font: normal normal bold 1.125rem/40px Pretendard;
    letter-spacing: 0px;
    color: #f0f0f0;
}

/* 롤링 배너 */
.company-rollingbanner {
    width: 100%;
    background-color: #fff;
}

.company-rollingbanner > .company-rollingWrap {
    position: relative;
    width: 1300px;
    height: 150px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
}

.company-rollingWrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to right, rgb(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 51%, rgba(255, 255, 255, 0) 100%);
}

.company-rollingWrap::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to right, rgb(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 51%, rgba(255, 255, 255, 1) 100%);
}

.company-banner-box {
    position: absolute;
    height: 100%;
}

.company-banner-box > ul {
    margin: 0;
    list-style: none;
    padding: 9px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.company-banner-box > ul > li {
    padding-right: 30px;
}

.company-banner-item {
    width: 280px;
    height: 108px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000008;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 애니메이션 */
.company-banner-box.original {
    animation: 70s linear 0s infinite normal forwards running rollingleft1;
}
.company-banner-box.clone {
    animation: 70s linear 0s infinite normal none running rollingleft2;
}
@keyframes rollingleft1 {
    /* 원본용 */
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-100%);
    }
    50.01% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes rollingleft2 {
    /* 클론용 */
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-200%);
    }
}

.home-flex-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-flex-title {
    text-align: left;
    font: normal normal 600 2rem/52px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 48px;
    padding-left: 24px;
    position: relative;
}

.home-flex-title::before {
    content: "";
    width: 5px;
    height: 36px;
    display: block;
    background-color: #3e54d3;
    position: absolute;
    top: 6px;
    left: 0;
}

.home-flex-title span {
    font-size: 1rem;
    color: #3e54d3;
    display: inline-block;
    padding-left: 12px;
}

.home-flex-inner-box {
    width: 48%;
}

.home-flex-inner-box ul {
    counter-reset: item;
}

.home-flex-inner-box ul li {
    font: normal normal 400 1.1rem/28px Pretendard;
    letter-spacing: 0px;
    word-break: keep-all;
    white-space: break-spaces;
    position: relative;
}

.home-flex-inner-box ul li::before {
    display: block;
    position: absolute;
    color: rgba(51, 51, 51, 0.445);
    font-family: "Pretendard" !important;
    font-size: 40px;
    font-weight: bold;
    content: counter(item, decimal-leading-zero);
    counter-increment: item;
    top: -32px;
    left: -20px;
}
/* 
.home-flex-inner-box ul li:nth-child(3n + 1)::before {
    top: -32px;
    left: -10px;
}

.home-flex-inner-box ul li:nth-child(3n + 2)::before {
    top: -32px;
    left: -10px;
}
.home-flex-inner-box ul li:nth-child(3n)::before {
    top: -32px;
    left: -10px;
} */

.home-flex-inner-box ul li:not(:last-of-type) {
    margin-bottom: 80px;
}

.home-flex-cover img {
    width: 620px;
}

.contents_table_wrap {
    display: table;
    table-layout: fixed;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.contents_table_itemBox {
    display: table-cell;
    background-color: #fff;
    height: 300px;
    border: 1px solid #f1f1f1;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.contents_table_itemBox:nth-of-type(2n) {
    background-color: #f7f7f7ce;
}

.contents_table_itemBox h3 {
    color: var(--unnamed-color-1c2126);
    font: normal normal bold 1.2rem/28px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 14px;
}
.contents_table_itemBox p {
    font: normal normal 500 0.9rem/24px Pretendard;
    letter-spacing: 0px;
    color: #464646;
    margin-bottom: 14px;
    word-break: break-all;
    white-space: break-spaces;
}

.contents_table_itemBox a {
    text-decoration: none;
}

.contents_table_item_cover {
    margin-top: 50px;
    margin-bottom: 16px;
}

.contents_table_item_cover img {
    width: 4rem;
}

.contents_understand_cover {
    text-align: center;
}

.contents_understand_cover img {
    width: 95%;
}

.ask-text {
    text-align: center;
    font: normal normal 500 1rem/28px Pretendard;
    color: #f1f1f1;
    word-break: keep-all;
    margin-top: 16px;
}

/* 모바일 뷰포트 */

@media screen and (max-width: 767px) {
    .home-flex-wrap {
        display: block;
    }

    .home-flex-inner-box {
        width: 100%;
    }

    .home-flex-cover {
        margin-bottom: 64px;
    }

    .home-flex-cover img {
        width: 100%;
    }

    .home-flex-inner-box ul li {
        word-break: keep-all;
    }

    .home-flex-inner-box ul li::before {
        left: -5px;
    }

    .contents_title p {
        word-break: keep-all;
    }

    .contents_table_itemBox {
        display: table-row;
        height: 350px;
    }

    .contents_table_item_cover img {
        width: 40%;
    }

    .contents_table_itemBox p {
        font-size: 1.2rem;
    }

    .company-rollingbanner > .company-rollingWrap {
        width: 100%;
    }

    .ask-title {
        word-break: keep-all;
    }
}
