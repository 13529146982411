.pageTitle_container {
    padding: 150px 0 90px 0;
}

.pageTitle_background {
    min-height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pageTitle_box {
    text-align: center;
}

.pageTitle-sub {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #3e54d3;
    border-radius: 30px;
    text-align: center;
    font: normal normal 800 18px/18px GangwonEduPower;
    letter-spacing: 0px;
    color: #3e54d3;
    margin-bottom: 24px;
    padding: 10px 30px;
}

.pageTitle_box .sub-w {
    color: #f1f1f1;
    width: 800px;
    word-break: break-all;
    white-space: pre-line;
    margin-left: auto;
    margin-right: auto;
}

.pageTitle_box h2 {
    text-align: center;
    letter-spacing: 0px;
    color: #1c2126;
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 24px;
}

.pageTitle_box p {
    text-align: center;
    font: normal normal normal 18px/24px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.pageTitle_background .pageTitle-sub {
    background: transparent 0% 0% no-repeat padding-box;
    border: 2px solid #fff;
    border-radius: 30px;
    text-align: center;
    font: normal normal 800 22px/22px GangwonEduPower;
    letter-spacing: 0px;
    color: #ffffff;
}

.pageTitle_background h2 {
    color: #f1f1f1;
}
