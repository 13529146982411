.disabled {
    pointer-events: none;
    cursor: no-drop;
}

.active {
    cursor: pointer;
    color: #fff;
    background: #618ce1 0% 0% no-repeat padding-box;
}

.page-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
