.homepage_mainBN_container {
    background-image: url(../../../../public/img/main-banner7.jpg);
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.homepage_mainBN_container2 {
    background-image: url(../../../../public/img/main-banner3.jpg);
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.homepage_mainBN_container3 {
    background-image: url(../../../../public/img/main-banner6.jpg);
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.homepage_mainBN_container4 {
    background-image: url(../../../../public/img/main-banner5.jpg);
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.homepage_mainBN_inner_box {
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translate(-10%, -50%);
    width: 1000px;
}
.homepage_mainBN_inner_box2 {
    position: absolute;
    bottom: 0;
    left: 8%;
    transform: translate(-10%, -50%);
    width: 1000px;
}

.homepage_mainBN_inner_box h2 {
    text-align: left;
    font: normal normal 500 2rem/54px Pretendard;
    letter-spacing: 1.08px;
    color: #f1f1f1;
}

.homepage_mainBN_inner_box h2 span {
    text-align: left;
    font: normal normal 700 3.5rem/62px Pretendard;
    letter-spacing: 1.08px;
    color: #f1f1f1;
    margin-bottom: 64px;
}

.homepage_mainBN_inner_box2 h3 {
    /* width: 800px; */
    text-align: left;
    font: normal normal 700 1.5rem/36px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
    margin-bottom: 4px;
}

.homepage_mainBN_inner_box2 p {
    /* width: 1000px; */
    text-align: left;
    font: normal normal 400 1.2rem/36px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
}

/* 모바일 */

@media screen and (max-width: 767px) {
    .homepage_mainBN_container {
        background-image: url(../../../../public/img/main-banner7_mo.jpg);
    }
    .homepage_mainBN_container2 {
        background-image: url(../../../../public/img/main-banner3_mo.jpg);
    }
    .homepage_mainBN_container3 {
        background-image: url(../../../../public/img/main-banner6_mo.jpg);
    }
    .homepage_mainBN_container4 {
        background-image: url(../../../../public/img/main-banner5_mo.jpg);
    }

    .homepage_mainBN_container,
    .homepage_mainBN_container2,
    .homepage_mainBN_container3,
    .homepage_mainBN_container4 {
        width: 100vw;
        height: 900px;
    }

    .homepage_mainBN_inner_box {
        width: 100%;
        top: 30%;
        left: 0%;
        transform: translate(0, 0);
    }

    .homepage_mainBN_inner_box h2 {
        text-align: center;
        word-break: keep-all;
        padding: 0 10px;
    }

    .homepage_mainBN_inner_box h2 span {
        display: block;
        margin-top: 20px;
        text-align: center;
    }

    .homepage_mainBN_inner_box2 {
        width: 100%;
        top: 70%;
        left: 0%;
        transform: translate(0, 0);
    }

    .homepage_mainBN_inner_box2 h3 {
        text-align: center;
        word-break: keep-all;
        padding: 0 10px;
    }

    .homepage_mainBN_inner_box2 p {
        text-align: center;
        padding: 0 10px;
        word-break: keep-all;
    }
}
