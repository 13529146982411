.header_container {
    width: 100vw;
    position: fixed;
    z-index: 2;
    background-color: transparent;
    pointer-events: auto;
}

.header_change {
    background-color: #fff;
    transition: all 0.4s;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.header-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background-color: white;
    transition: height 0.3s;
    overflow: hidden;
    pointer-events: none;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.header-bg_hover {
    height: 100%;
}

.header_inner_cont {
    position: relative;
    padding: 11px 20px 10px 20px;
    /* min-height: 60px; */
    box-sizing: border-box;
}

.header_lnb_cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header_menu_list {
    display: flex;
    width: 639px;
    justify-content: space-between;
    align-items: center;
}

.header_menu_list li:nth-of-type(2),
.header_menu_list li:nth-of-type(3) {
    padding-left: 20px;
}

.header_menu_list li div {
    text-align: center;
    font: normal 700 16px/29px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
    pointer-events: none;
}

.menuActive {
    background-color: #fff;
}
.menuNone {
    background-color: none;
}

.dropdown-box {
    display: flex;
    justify-content: center;
    margin-left: 140px;
}

.menu_link_text {
    font: normal 400 14px/16px Pretendard;
    letter-spacing: 0px;
    color: #747474;
    text-decoration: none;
}
