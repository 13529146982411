.ask-contents-width {
    width: 1000px;
    padding: 50px 0;
}

.ask-message-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.ask-cover::after {
    content: "";
    width: 95px;
    height: 4px;
    display: block;
    background-color: #bbbbbb;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 32%;
}

.ask-message-box p {
    width: 319px;
    text-align: left;
    font: normal normal normal 16px/19px Pretendard;
    letter-spacing: 0px;
    color: #818181;
    word-break: break-all;
    white-space: pre-line;
}

.ask-form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.form-control {
    width: 420px;
    height: 40px;
    border: 1px solid #dddddd;
}

select.form-control {
    width: 426px;
}

.text-control {
    width: 1060px;
    height: 169px;
    resize: none;
}

.ask-form-item {
    display: table;
    margin-bottom: 16px;
}

.ask-form-item label,
.ask-form-item select .ask-form-item input,
.ask-form-item textarea {
    display: table-cell;
    vertical-align: top;
}

.ask-form-item label {
    width: 100px;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.form-control:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgba(70, 125, 197, 0.4);
}

.confirm-ck {
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #343448;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.c-link {
    text-decoration: underline;
    font: normal normal bold 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #3e54d3;
    cursor: pointer;
}

.askSubmit-box {
    text-align: right;
    padding-right: 60px;
    margin-top: 24px;
}

.askBtn {
    background: #4f80e2 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 10px 40px;
    border: none;
    text-align: center;
    font: normal normal bold 14px/24px Pretendard;
    letter-spacing: 0px;
    color: #ffffff;
}

.keep_title h3,
.guide_title h3 {
    text-align: center;
    font: normal normal bold 32px/46px Pretendard;
    letter-spacing: 0px;
    color: #000000;
}

.keep_title,
.guide_title {
    margin-bottom: 68px;
}

.guide_title {
    padding-bottom: 24px;
    border-bottom: 1px solid #d8d8d8;
}

.keep_cont {
    text-align: center;
    padding-bottom: 32px;
}

.keep_item:first-of-type {
    margin-bottom: 100px;
}

.keepItem_title {
    position: relative;
    border-top: 1px solid #d8d8d8;
    height: 30px;
    margin-bottom: 32px;
}

.keepItem_title h4 {
    display: inline-block;
    width: 230px;
    background-color: #fff;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font: normal normal bold 24px/35px Pretendard;
    letter-spacing: 0px;
    color: #000000;
}

.keepItem_title h4::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #343448;
    border-radius: 4px;
    position: absolute;
    top: 45%;
    left: 0;
}
.keepItem_title h4::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #343448;
    border-radius: 4px;
    position: absolute;
    top: 45%;
    right: 0;
}

.guide_text {
    width: 207px;
    height: 48px;
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #535353;
    margin-bottom: 16px;
}

.moveBtn {
    text-align: right;
}

.moveBtn span {
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #535353;
}

.guide_cont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.guide_item {
    width: 408px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #00000012;
    border: 1px solid #d8d8d8;
    border-radius: 34px;
    padding: 24px;
}

.guideItem_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 32px;
}

.guideItem_title h4 {
    width: 161px;
    text-align: left;
    font: normal normal bold 32px/46px Pretendard;
}

.pop_contents_inner_container_layout input,
.pop_contents_inner_container_layout select,
.pop_contents_inner_container_layout textarea {
    width: 600px;
}

.pop_contents_inner_container_layout input[type="checkbox"] {
    width: auto;
}

.ask-introText {
    text-align: center;
    padding-top: 24px;
    font: normal normal normal 18px/24px Pretendard;
    color: #f1f1f1;
}

.lable-red {
    color: rgb(255, 2, 2);
    font-weight: 700;
    font-size: 13px;
}

.pop_contents_inner_container_layout .confirm-ck {
    width: auto;
    padding-left: 32px;
}

.pop_contents_inner_container_layout .askSubmit-box {
    text-align: center;
    padding: 0;
}

.pop_contents_inner_container_layout .askBtn {
    width: 90%;
}

.askSubmit-box p {
    padding-bottom: 16px;
    font: normal normal normal 14px/18px Pretendard;
    color: crimson;
}
