.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* 팝업 */

.popup {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}

.popup section {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    overflow: hidden;
    text-align: center;
}
.popup-none {
    display: none;
}
.popup main {
    padding: 30px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    color: #000;
    font-size: 20px;
    /* font-weight: 600; */
}
.popup footer {
    padding: 12px 16px;
    text-align: right;
}

.footerbtn {
    padding: 12px 24px;
    color: #fff;
    background-color: #3e54d3;
    border-radius: 5px;
    font-size: 13px;
    border: none;
    cursor: pointer;
}

.openModal {
    display: flex;
    align-items: center;
}

.readyPop section {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    overflow: hidden;
    text-align: center;
}

.readyPop section main h2 {
    font-weight: 700;
    color: #282c34;
    font-size: 42px;
    font-family: "Pretendard";
    margin: 32px 0;
}

.readyPop section main p {
    font-weight: 500;
    color: #484a4d;
    font-size: 24px;
    font-family: "Pretendard";
    line-height: 32px;
}
.endDate {
    display: flex;
    justify-content: center;
}

.endDate > div {
    padding: 18px;
    color: #f1f1f1;
    background-color: #333;
    width: 400px;
    margin-top: 32px;
    border-radius: 28px;
}
