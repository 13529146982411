.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}

.modal > section {
    width: 90%;
    max-width: 640px;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.3s;
    overflow: hidden;
}
.modal > section > header {
    position: relative;
    padding: 16px;
    background-color: #f1f1f1;
    font-weight: 700;
    color: #333;
    text-align: center;
    font: normal normal bold 16px/24px Pretendard;
}
.modal > section > header button {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 30px;
    font-size: 31px;
    font-weight: 700;
    text-align: center;
    color: #0e182e;
    background-color: transparent;
}
.modal > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    color: #000;
}
.modal > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modal > section > footer button {
    padding: 12px 24px;
    color: #fff;
    background-color: #3e54d3;
    border-radius: 5px;
    font: normal normal bold 13ㅔㅌ/24px Pretendard;
}

.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-cover-title {
    width: 200px;
    height: 300px;
    text-align: center;
    background-color: #3e54d3;
}

.modal main {
    width: 100%;
}

.modal main p {
    /* letter-spacing: 0.4px; */
    color: #737373;
    word-break: break-all;
    white-space: pre-line;
    font-family: "Pretendard";
}
