.notice-list,
.news-list {
    width: 1180px;
    margin-left: auto;
    margin-right: auto;
}

.notice-list table,
.news-list table {
    width: 100%;
}

.list-title-box {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-top: 4px solid #3e54d3;
}

.list-title-box tr th {
    padding: 24px 0;
    text-align: center;
    letter-spacing: 0px;
    color: #1c2126;
    font: normal bold normal 16px/18px Pretendard;
}

/* .list-item-box {
    border-bottom: 4px solid #3e54d3;
} */

.notice-contents {
    border-bottom: 1px solid #e7e7e7;
}

.notice-contents td {
    padding: 24px 0;
    text-align: center;
    font: normal normal normal 18px/26px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.noticeLink {
    padding: 24px 0;
    text-align: left;
    font: normal normal normal 18px/26px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    text-decoration: none;
}

.notice-contents td:nth-of-type(2) {
    text-align: left;
}

.page_item {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

/* 포스트 본문 */

.listBack-btn {
    background: #3e54d3 0% 0% no-repeat padding-box;
    padding: 14px 30px;
    border: none;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #ffffff;
    cursor: pointer;
}

.post-title_item {
    width: 100%;
}

.post-title_item tr {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.post-title_item tr th,
.post-title_item tr td {
    padding: 18px 0;
}

.post-title_item tr th {
    background: #f7f7f7 0% 0% no-repeat;
    text-align: center;
    font: normal normal bold 16px/18px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.post-title_item tr td {
    padding-left: 30px;
    text-align: left;
    font: normal normal normal 16px/18px Pretendard;
}

.post-contents {
    padding: 32px 8px;
    font: normal normal normal 16px/18px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 64px;
    border-bottom: 1px solid #e6e6e6;
}

.news-list {
    border-top: 1px solid #d8d8d8;
}

.news-contents {
    border-bottom: 1px solid #e7e7e7;
}

.news-contents td {
    padding: 24px 0;
    text-align: left;
    font: normal normal normal 18px/26px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    vertical-align: middle;
}

.news-contents td:last-of-type {
    text-align: right;
    padding-right: 12px;
}

.news_date {
    color: #494949;
    font-size: 15px;
    margin-bottom: 8px;
}

.newsLink {
    font-size: 24px;
    font-weight: bold;
}

.case-item-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.case-item {
    text-decoration: none;
    color: #494949;
    margin-bottom: 24px;
    width: 30%;
}

.caseItem-cont h4 {
    text-align: left;
    font: normal normal bold 18px/56px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.caseItem-cont p {
    text-align: left;
    font: normal normal normal 18px/25px Pretendard;
    letter-spacing: 0px;
    color: #494949;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.case_title {
    padding-left: 24px;
    margin-bottom: 50px;
}

.case_title h3 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 32px/25px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 16px;
}

.case_title p {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal normal 18px/25px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.custUseCase_title {
    padding: 54px 0;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 65px;
}

.custUseCase_title_sub {
    text-align: left;
    font: normal normal normal 16px/19px Pretendard;
    letter-spacing: 0px;
    color: #969696;
    margin-bottom: 14px;
}

.custUseCase_title h3 {
    text-align: left;
    font: normal normal bold 28px/34px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.useCase_text {
    margin-bottom: 70px;
}

.useCase_text h4 {
    margin-bottom: 40px;
    text-align: left;
    font: normal normal bold 24px/29px Pretendard;
    letter-spacing: 0px;
    color: #3472d1;
}

.useCase_text p {
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.custInfo-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.caseUse_cover {
    flex: 1;
}

.custInfo-item {
    flex: 2;
}
.custInfo-item table {
    width: 100%;
}

.custInfo-item table tr {
    border-top: 1px solid #d8d8d8;
}

.custInfo-item table tr:last-of-type {
    border-bottom: 1px solid #d8d8d8;
}

.custInfo-item table tr td {
    text-align: left;
    font: normal normal normal 16px/19px Pretendard;
    letter-spacing: 0px;
    color: #343448;
    padding: 18px 0;
}

.custInfo-item table tr td:first-of-type {
    font-weight: bold;
}

.useText_cont {
    margin-bottom: 60px;
}
