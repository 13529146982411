.suze_title_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.suze_title_cont h3,
.suez_layout_title h3,
.suez_type_title h3 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal bold 36px/60px Pretendard;
    letter-spacing: 0.5px;
    color: #1c2126;
}

.suze_title_cont video {
    margin-left: auto;
    margin-right: auto;
    padding: 60px 0;
}

.suez_layout_title {
    margin-bottom: 64px;
}

.suze_title_cont p {
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
    text-align: center;
    font: normal normal normal 18px/26px Pretendard;
    letter-spacing: 0.4px;
    color: #494949;
}

.suez_layout_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.suez_layout_cont:not(:last-of-type) {
    margin-bottom: 54px;
}

.suez_layout_cont:last-of-type {
    flex-direction: row-reverse;
}

.suez_layout_item {
    flex: 1;
}

.suez_layout_item h4 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 36px/52px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.suez_layout_item p {
    text-align: left;
    font: normal normal normal 18px/24px Pretendard;
    letter-spacing: 0px;
    color: #494949;
    padding-top: 24px;
    width: 580px;
}

.suez_layout_item img {
    width: 624px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.suez_type_cont ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suez_type_cont ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    margin-top: 60px;
    padding-right: 16px;
}

.suez_type_cover {
    flex: 1;
}

.suez_type_list {
    flex: 2;
}

.suez_type_list h4 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 26px/35px Pretendard;
    letter-spacing: 0.42px;
    color: #1c2126;
    margin-bottom: 12px !important;
}

.suez_type_list p {
    text-align: left;
    font: normal normal normal 16px/26px Pretendard;
    letter-spacing: 0.4px;
    color: #494949;
    word-break: keep-all;
}
