.greeting-title {
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 60px;
    padding-bottom: 30px;
}

.greeting-title h3 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal bold 32px/38px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}
/* 
.greeting-cont {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
} */

/* .greeting-text {
    padding-left: 30px;
    padding-right: 30px;
    flex: 1;
} */

.greetingText_item {
    position: relative;
    margin-bottom: 48px;
}

.item_strong {
    font: normal normal 800 42px/42px Pretendard;
    position: absolute;
    top: -16px;
    opacity: 0.7;
    /* font-style: italic; */
}

.item_text {
    padding-left: 200px;
    text-align: left;
    font: normal normal 600 18px/28px Pretendard;
    letter-spacing: 1px;
    color: #343448;
    word-break: keep-all;
    white-space: pre-line;
    letter-spacing: 1px;
}

.greeting-text h4 {
    color: var(--unnamed-color-3e54d3);
    text-align: center;
    font: normal normal bold 32px/42px Pretendard;
    letter-spacing: 0px;
    color: #3e54d3;
    margin-bottom: 80px;
}

.greeting-text p {
    text-align: left;
    font: normal normal normal 1.2rem/28px Pretendard;
    letter-spacing: 1px;
    color: #343448;
    word-break: keep-all;
    white-space: pre-line;
}

.greeting-text p h5 {
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    padding-left: 1.3rem;
    margin-bottom: 8px;
}

.greeting-text p h5::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #3e54d3;
}

.greeting-text p strong {
    font-weight: bold;
    font-size: 1.5rem;
}
.greeting-text p .text-blue {
    color: #618ce1;
    font-weight: 600;
}

.greeting-cover {
    width: 40%;
    overflow: hidden;
}

.greeting-cover img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.vision-title {
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 60px;
    padding-bottom: 30px;
}

.vision-title p {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal normal 20px/38px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

/* .vision-cont {

} */

.vision-text {
    padding-left: 30px;
    padding-right: 68px;
    flex: 1;
}

.vision-text h4 {
    color: var(--unnamed-color-3e54d3);
    text-align: left;
    font: normal normal bold 32px/38px Pretendard;
    letter-spacing: 0px;
    color: #3e54d3;
    margin-bottom: 40px;
}

.vision-text p {
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 1px;
    color: #343448;
    word-break: keep-all;
    white-space: pre-line;
}

.vision_item {
    position: relative;
}

.vision_item:not(:last-of-type) {
    margin-bottom: 24px;
}

.vision-cover {
    width: 200px;
    position: absolute;
}

.vision-cover img {
    width: 100%;
}

.vision_item_text {
    padding-top: 60px;
    padding-left: 130px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-radius: 8px;
    height: 200px;
    margin-left: 100px;
}

.vision_item_text h4 {
    margin-bottom: 16px;
    font: normal normal 500 24px/36px Pretendard;
    color: #343448;
}

.vision_item_text h4 span {
    color: #3e54d3;
}

.vision_item_text p {
    margin-bottom: 16px;
    font: normal normal normal 18px/28px Pretendard;
    color: #343448;
    word-break: keep-all;
}

.value-box {
    margin-top: 100px;
}

.value-title {
    text-align: center;
    margin-bottom: 100px;
}

.value-title h4 {
    text-align: center;
    margin-bottom: 16px;
    font: normal normal bold 36px/43px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.value-title p {
    text-align: center;
    font: normal normal normal 18px/24px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.value-cont {
    text-align: center;
}

.value-item-text {
    margin-top: 150px;
}

.value-item-text p {
    width: 1058px;
    margin: 0 auto;
    text-align: center;
    font: normal normal normal 18px/30px Pretendard;
    letter-spacing: 0px;
    color: #343448;
}

.history-title {
    padding-bottom: 48px;
    margin-bottom: 80px;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
}

.history-title h3 {
    font: normal normal bold 40px/30px Pretendard;
    letter-spacing: 0px;
    color: #000000;
}

.history-year {
    display: flex;
}

.history-year h2 {
    font-size: 32px;
    color: #1c2126;
    width: 33.333333%;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: -0.01em;
    text-align: left;
    padding-right: 99px;
    box-sizing: border-box;
}

.history-year-inside {
    line-height: 1.76;
    width: 66.666666%;
    box-sizing: border-box;
    position: relative;
}

.history-contents::before {
    content: " ";
    position: absolute;
    top: 14px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: #3e54d3;
    border-radius: 50%;
    transform: translateX(-50%);
}

.history-contents {
    position: relative;
    padding: 5px 50px 10px 99px;
    border-left: 1px solid #bbddfd;
}

.history-contents strong {
    color: #3e54d3;
    font: normal normal bold 22px/30px Pretendard;
    white-space: nowrap;
    margin-bottom: 41px;
    display: block;
}

.history-contents ul > li {
    color: #1c2126;
    font: normal normal normal 18px/30px Pretendard;
    white-space: nowrap;
    display: block;
}

.history-contents ul > li > a {
    text-decoration: none;
}

.history-contents ul > li > a:visited {
    color: #1c2126;
}

.history-contents ul {
    margin-bottom: 50px;
}
