.df_intro_wrap {
    position: relative;
    min-height: 650px;
}

.df-intro_container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-20%, -50%);
    width: 80%;
}

.df-intro_container h3 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal 700 2.25rem/62px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
    margin-bottom: 68px;
}

.df-intro_container p {
    font: normal normal 500 1.5rem/36px Pretendard;
    letter-spacing: 0px;
    color: #f2f2f2;
    word-break: break-all;
    white-space: break-spaces;
}

.df-intro_container p strong {
    font-weight: bold;
}

.df_type_header {
    margin-bottom: 63px;
}

.df_type_header h3 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal bold 2.25rem/52px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.df_type_header h3 img,
.suze_title_cont h3 img,
.suez_layout_title h3 img {
    width: 36px;
    margin-left: 16px;
    margin-right: 16px;
}

.df_type_header p {
    width: 816px;
    text-align: center;
    font: normal normal normal 1.125rem/1.5rem Pretendard;
    letter-spacing: 0px;
    color: #494949;
    word-break: break-all;
    white-space: break-spaces;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
}

.df_type_cont {
    display: flex;
    justify-content: space-around;
}

.df_type_item {
    flex: 1;
    text-align: center;
}

.df_type_item:first-of-type {
    margin-right: 60px;
}

.df_type_item img {
    width: 550px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.df_type_item h4 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal bold 1.125rem/21px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    padding-top: 54px;
}

.df_type_item p {
    text-align: center;
    font: normal normal normal 1rem/24px Pretendard;
    letter-spacing: 0px;
    color: #494949;
    padding-top: 16px;
}

.df_feature_header {
    text-align: left;
    margin-bottom: 64px;
}

.df_feature_header h3::before {
    content: "";
    width: 5px;
    height: 36px;
    display: block;
    background-color: #3e54d3;
    position: absolute;
    top: 6px;
    left: 0;
}

.df_feature_header h3 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal 600 2.25rem/52px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 48px;
    padding-left: 24px;
    position: relative;
}

.df_feature_header p {
    text-align: left;
    font: normal normal normal 1.125rem/28px Pretendard;
    letter-spacing: 0px;
    color: #494949;
}

.df_feature_item {
    flex: 1;
    padding-right: 50px;
    text-align: center;
}

.df_feature_item > img {
    width: 560px;
}

.df_feature_item p {
    text-align: left;
    font: normal normal normal 1rem/24px Pretendard;
    letter-spacing: 0px;
    color: #494949;
    margin-top: 18px;
}

.df_feature_item ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.df_feature_item ul li {
    width: 47%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.df_feature_item ul li h4 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 1.5rem/32px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.df_feature_item ul li > div {
    flex: 1;
}

.df_feature_item ul li img {
    width: 60px;
    margin-right: 48px;
}

.df_need_type_cont ul {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.df_need_type_cont ul li {
    display: table-cell;
    text-align: center;
}

.df_need_cover {
    width: 148px;
    height: 148px;
    border-radius: 74px;
    border: 1px solid #f1f1f1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.df_need_cover img {
    width: 72px;
}

.df_need_text {
    color: #494949;
    font: normal normal normal 1.125rem/24px Pretendard;
}

.df_point_cont {
    display: table;
    table-layout: fixed;
}

.df_point_item {
    display: table-cell;
    width: 25%;
    margin-bottom: 50px;
    padding: 60px 24px;
    text-align: center;
    border: 1px solid #e9ebf0;
}

.df_point_item h4 {
    text-align: center;
    font: normal normal bold 1.5rem/32px Pretendard;
    letter-spacing: 0px;
    color: #074f85;
}

.df_point_item p {
    text-align: center;
    font: normal normal normal 1rem/24px Pretendard;
    letter-spacing: 0px;
    color: #464646;
    margin-top: 18px;
    word-break: break-all;
    white-space: break-spaces;
    word-spacing: 1px;
}

.df_point_item_cover {
    background-color: #f7f8fc;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.df_point_item_cover img {
    width: 60%;
}

.df_point_row {
    display: table-row;
}

.service_cont_cover {
    width: 100%;
    text-align: center;
    padding-top: 100px;
}

.df_service_text {
    padding-bottom: 32px;
}

.df_service_text p {
    text-align: center;
    font: normal normal normal 1.125rem/32px Pretendard;
    letter-spacing: 0px;
    color: #494949;
    margin-top: 18px;
    word-break: break-all;
    word-spacing: 1px;
}

.df_service_type_cont ul {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.df_service_type_cont ul li {
    display: table-cell;
    text-align: center;
    padding: 0px 10px;
}

.df_serviceItem_text {
    border: 1px solid #e6e6e6;
    border-radius: 16px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.df_serviceItem_text p {
    text-align: center;
    font: normal normal normal 1.25rem/28px Pretendard;
    color: #494949;
}

.df_case_cont_tab {
    margin-top: 100px;
    margin-bottom: 24px;
}

.df_case_cont_tab ul {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-bottom: 1px solid #e6e6e6;
}

.df_case_cont_tab ul li {
    /* height: 80px; */
    display: table-cell;
    background-color: #2d5679;
    padding: 18px;
    cursor: pointer;
}

.df_case_cont_tab ul li:not(:last-of-type) {
    border-right: 1px solid #e6e6e6;
}

.df_case_cont_tab ul li:nth-of-type(n + 2) {
    pointer-events: none;
    visibility: hidden;
}

.df_case_cont_tab ul li h4 {
    color: #f1f1f1;
    font: normal normal 400 1.5rem/32px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 8px;
}

.df_case_cont_tab ul li span {
    font: normal normal 300 1.125rem/28px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
}

.tabActive {
    /* height: 80px; */
    display: table-cell;
    background-color: #fff !important;
    padding: 18px;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
}

.df_case_cont_tab ul li.tabActive h4 {
    color: #074f85;
    font: normal normal 500 1.5rem/32px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 8px;
}

.df_case_cont_tab ul li.tabActive span {
    font: normal normal 400 1.125rem/28px Pretendard;
    letter-spacing: 0px;
    color: #074f85;
}

.df_case_item_cover {
    text-align: center;
}

.df_case_item_cover img {
    width: 98%;
}

/*  모바일 */

@media screen and (max-width: 767px) {
    .df-intro_container {
        position: relative;
        width: 100%;
        text-align: center;
    }

    .df-intro_container h3 {
        text-align: center;
        word-break: keep-all;
    }
}
