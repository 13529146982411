.page_sub_title h3 {
    color: var(--unnamed-color-1c2126);
    text-align: center;
    font: normal normal 600 36px/54px Pretendard;
    letter-spacing: 0.2px;
    color: #1c2126;
}

.page_sub_title h3 span img {
    width: 36px;
    margin-left: 16px;
    margin-right: 16px;
}

.page_sub_title p {
    margin-left: auto;
    margin-right: auto;
    width: 786px;
    text-align: center;
    font: normal normal normal 18px/28px Pretendard;
    letter-spacing: 0.4px;
    color: #4e4e4e;
    padding-top: 28px;
}

.page_sub_cover {
    text-align: center;
    padding-top: 60px;
}

.page_sub_cover img {
    width: 100%;
}

.product_coverList_cont {
    padding-top: 70px;
}

.product_coverList_cont > ul {
    display: table;
    table-layout: fixed;
    border-spacing: 8px;
    width: 100%;
}

.product_coverList_cont > ul > li {
    display: table-cell;
    text-align: center;
    /* border: 1px solid #fff; */
    /* padding: 24px; */
}

.product_coverList_cont ul > li > p {
    word-break: break-all;
    white-space: pre-line;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.7px;
    font: normal normal 600 16px/24px Pretendard;
    color: #333;
}

.text_w p,
.text_w h2,
.text_w h3,
.text_w ul li h4,
.text_w ul li p,
.text_w ul li div {
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
}

.coverList_cover {
    margin-bottom: 60px;
}

.coverList_cover img {
    width: 30%;
}

.product_effect_cover img {
    width: 60%;
}

.product_effect_cover {
    background-color: #fff;
    /* box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1); */
    padding: 54px 0;
    /* margin-bottom: 24px; */
}

.product_effect_list h4 {
    text-align: center;
    font: normal normal bold 40px/56px Pretendard;
    letter-spacing: 0px;
    color: #343448;
    padding-bottom: 16px;
}

.product_effect_list p {
    word-break: break-all;
    white-space: pre-line;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.4px;
    font: normal normal normal 20px/24px Pretendard;
}

.product_coverList_cont ul li p {
    /* width: 300px; */
    word-break: break-all;
    white-space: pre-line;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.7px;
    font: normal normal 500 16px/30px Pretendard;
    color: #333;
}

.product_coverList_cont ul li p > strong {
    font-weight: 800;
    font-size: 20px;
}

.product_type_cont {
    margin-top: 60px;
}

.product_type_cont table {
    width: 100%;
    border-spacing: 0;
}

.type_table_header th:not(:first-of-type) {
    background-color: #568cf7;
}

.type_table_header th {
    padding: 24px 0;
    color: #f1f1f1;
    font-weight: bold;
    font: normal normal bold 24px/32px Pretendard;
}

.type_table_header th:nth-of-type(2) {
    border-top-left-radius: 24px;
    border-right: 1px solid #f1f1f1;
}

.type_table_header th:last-of-type {
    border-top-right-radius: 24px;
}

.type_table_body th,
.type_table_body td {
    border: 1px solid #d8d8d8;
    padding: 32px 8px;
    vertical-align: middle;
    font: normal normal normal 18px/24px Pretendard;
    color: #343448;
}

.analtyics_layout_cont {
    padding-top: 54px;
}

.analtyics_layout_item {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.analtyics_layout_item:first-of-type {
    margin-bottom: 70px;
}

.analtyics_layout_item img {
    width: 500px;
    margin-bottom: 16px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.analtyics_layout_item p {
    text-align: center;
    font: normal normal normal 16px/26px Pretendard;
    letter-spacing: 0.4px;
    color: #333;
}

.product_feature_item img {
    width: 100%;
}

.product_feature_cont {
    margin-top: 90px;
    display: flex;
    align-items: center;
}

.product_feature_item {
    flex: 1;
    padding-right: 92px;
}

.product_feature_item:last-of-type {
    padding: 0;
}

.product_feature_item p {
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #333;
    margin-top: 16px;
    word-break: keep-all;
}

.product_feature_item ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product_feature_item ul li:not(:last-of-type) {
    margin-bottom: 64px;
}

.product_feature_item ul li h4 {
    color: var(--unnamed-color-1c2126);
    text-align: left;
    font: normal normal bold 24px/29px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.product_system_cover {
    text-align: center;
}

.product_difference_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}

.product_difference_cont:nth-of-type(odd) {
    flex-direction: row-reverse;
}

.product_difference_item {
    flex: 1;
}

.product_difference_item h4 {
    color: var(--unnamed-color-1c2126);
    text-align: right;
    font: normal normal bold 32px/40px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
}

.product_difference_item p {
    text-align: right;
    font: normal normal normal 16px/32px Pretendard;
    letter-spacing: 0px;
    color: #737373;
    padding-top: 24px;
}
.product_difference_cont:nth-of-type(odd) p,
.product_difference_cont:nth-of-type(odd) h4 {
    text-align: left;
}

.reason_h4 h4 {
    font-size: 32px;
}

.product_difference_item img {
    width: 100%;
}

.product_coverList_cont ul li h3 {
    font: normal normal bold 32px/48px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 18px;
}

.product_coverList_cont ul li h4 {
    font: normal normal bold 22px/32px Pretendard;
    letter-spacing: 0px;
    color: #1c2126;
    margin-bottom: 18px;
}

.background-black-w .product_effect_list {
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    padding: 24px;
    color: #f1f1f1;
    font: normal normal bold 22px/32px Pretendard;
}

.page_main_cover {
    text-align: center;
    padding: 64px 0;
    background-color: #f1f1f1;
}

.page_main_cover img {
    width: 100%;
}

.coverList_cover {
    width: 100%;
    /* height: 308px; */
    border-radius: 50%;
    /* background-color: #fafafa; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.effect-table {
    padding-top: 64px;
}

.effect-table ul {
    display: table;
    table-layout: fixed;
    border-spacing: 10px;
    width: 100%;
}

.effect-table ul li {
    display: table-cell;
    text-align: center;
}

.effect-table-cell {
    border: 1px solid #fff;
    padding: 24px 10px;
}

.effect-table-cell h4 {
    font: normal normal bold 28px/32px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 24px;
}

.effect-table-cell h4 span {
    color: #2262e2;
    display: inline-block;
    margin-top: 8px;
}

.effect-table-cell p {
    width: 100%;
    word-break: keep-all;
    white-space: pre-line;
    text-align: center;
    font: normal normal normal 16px/24px Pretendard;
}

.point-cover {
    /* border-bottom: 1px solid #568cf7; */
    padding-bottom: 24px;
}

.point-list {
    width: 100%;
    display: block !important;
}

.product_point_cont h3 {
    font: normal normal 700 20px/24px Pretendard !important;
}

.point-list li {
    text-align: left !important;
    display: block !important;
    font: normal normal normal 16px Pretendard !important;
}

.point-list li:not(:last-of-type) {
    margin-bottom: 8px;
}

.suez-box-item {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
    text-align: center;
}

.suez-box-item img {
    width: 300px;
    margin-bottom: 28px;
}

.suez-box-item h3,
.suez-box-item p {
    text-align: left;
}
.round-content-container {
    margin-top: 60px;
}
.round-content-box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    min-height: 200px;
    padding: 80px 0;
}

.round-item {
    width: 340px;
    height: 340px;
    border: 2px solid #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.round-text div {
    color: #fff;
    padding: 0 24px;
}

.round-text-title {
    font: normal normal 600 20px/24px Pretendard;
    margin-bottom: 50px;
}

.round-text-bodyT {
    font: normal normal 400 18px/28px Pretendard;
    position: relative;
    padding-top: 24px !important;
}

.round-text-bodyT::before {
    height: 1px;
    width: 240px;
    content: "";
    display: block;
    background-color: #f1f1f1;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translate(-50%, 0);
}

.mc-effect_wrap {
    position: relative;
    counter-reset: item;
    margin-bottom: 90px;
}

.mc-effect_cont {
    position: relative;
    overflow: hidden;
    margin-top: 150px;
    min-height: 490px;
}

.mc-effect_cont:first-of-type {
    margin-top: 100px;
}

.mc-effect_cont::after {
    display: block;
    clear: both;
    height: 0;
    content: "";
    overflow: hidden;
}

.mc-effect_item_cover {
    position: relative;
    width: 66.1%;
    max-width: 870px;
    background: #f5f5f5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.mc-effect_item_cover p {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.mc-effect_item_cover img {
    width: 100%;
}

.mc-effect_item_cover::after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.4%;
    background-color: #ddd;
}

.mc-effect_item_cover::before {
    display: block;
    position: absolute;
    color: rgba(0, 0, 0, 0.05);
    font-family: "Pretendard" !important;
    font-size: 10vw;
    font-weight: bold;
    line-height: 10vw;
    content: counter(item, decimal-leading-zero);
    counter-increment: item;
}

.mc-effect_cont:nth-child(3n + 1) .mc-effect_item_cover {
    float: left;
    margin-bottom: -8%;
}

.mc-effect_cont:nth-child(3n + 2) .mc-effect_item_cover {
    margin: 0 7% -10.2% auto;
}

.mc-effect_cont:nth-child(3n) .mc-effect_item_cover {
    float: right;
    margin-bottom: -18.5%;
}

.mc-effect_cont:nth-child(3n) .mc-effect_item_text {
    float: left;
    width: 50.5%;
    min-height: 250px;
}
.mc-effect_cont:nth-child(3n + 2) .mc-effect_item_text {
    width: 60.5%;
}

.mc-effect_cont:nth-child(3n + 1) .mc-effect_item_cover::before {
    right: -36%;
    bottom: 18.2%;
}

.mc-effect_cont:nth-child(3n + 2) .mc-effect_item_cover::before {
    left: -35%;
    top: -0.5%;
}
.mc-effect_cont:nth-child(3n) .mc-effect_item_cover::before {
    left: -40%;
    bottom: 49.5%;
}

.mc-effect_item_text {
    position: relative;
    float: right;
    width: 66.1%;
    padding: 60px 8.5% 0 8.5%;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 4px -4px 46px -17px rgba(0, 0, 0, 0.1);
}

.mc-effect_item_text h4 {
    font: normal normal bold 28px/32px Pretendard;
    letter-spacing: 0px;
    margin-bottom: 24px;
}

.mc-effect_item_text p {
    width: 100%;
    word-break: break-all;
    white-space: pre-line;
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
}

.page_mainMB_cover {
    position: absolute;
    left: 50%;
    top: 108px;
    transform: translate(-50%, -50%);
}
.page_mainMB_cover img {
    width: 800px;
}

.product_effect_chartCover {
    text-align: center;
}

.mes-intro_container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-20%, -50%);
    width: 85%;
}

.mes-intro_container h3 {
    text-align: left;
    font: normal normal 700 2.25rem/62px Pretendard;
    letter-spacing: 0px;
    color: #f1f1f1;
    margin-bottom: 68px;
}

.mes-intro_container p {
    font: normal normal 500 1.2rem/36px Pretendard;
    letter-spacing: 0px;
    color: #f2f2f2;
    word-break: keep-all;
    white-space: break-spaces;
}

.mes_position_tab ul li:nth-of-type(n + 2) {
    pointer-events: all !important;
    visibility: visible !important;
}

.mes_position_tab ul li {
    border-right: 1px solid #e6e6e6;
}

.tabActive span {
    font-weight: 600 !important;
}

.page_main_cont p {
    font: normal normal 500 1.2rem/32px Pretendard;
    margin-bottom: 16px;
}

.mes_keyPoint ul li {
    counter-increment: list-number;
    line-height: 2;
    padding: 18px 0 18px 54px;
    border-top: 1px solid #4e4e4e;
    position: relative;
    font: normal normal 500 1.2rem/32px Pretendard;
}

.mes_keyPoint ul li h4 {
    font-weight: 800;
}

.mes_keyPoint ul li:last-of-type {
    border-bottom: 1px solid #4e4e4e;
}

.mes_keyPoint ul li::before {
    content: counter(list-number); /*list-number를 count하여 가상선택자before의 content로 담아준다.*/
    display: inline-block;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    font-family: Pretendard;
    background: #074f85;
    position: absolute;
    left: 0;
    top: 35px;
}

.mes_positionPoint_list {
    padding-top: 24px;
}

.mes_positionPoint_list ul li {
    counter-increment: list-number;
    line-height: 2;
    padding: 8px 0 8px 54px;
    position: relative;
    font: normal normal 500 1.2rem/32px Pretendard;
}

.mes_positionPoint_list ul li h4 {
    font-weight: 800;
}

.mes_positionPoint_list ul li::before {
    content: counter(list-number); /*list-number를 count하여 가상선택자before의 content로 담아준다.*/
    display: inline-block;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    font-family: Pretendard;
    background: #074f85;
    position: absolute;
    left: 0;
    top: 10px;
}

.mes_main_flex_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mes_main_flex_inner {
    width: 50%;
    padding: 0 10px;
}

.mes_main_flex_inner h3 {
    font: normal normal 700 1.35rem/32px Pretendard;
    word-break: keep-all;
    margin-bottom: 54px;
}

.mes_main_flex_inner p {
    font: normal normal 500 1.1rem/24px Pretendard;
    word-break: keep-all;
    margin-bottom: 32px;
}

.main_flex_cover {
    text-align: center;
}

.main_flex_cover img {
    width: 90%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.mes_point_cont {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.mes_point_item {
    display: table-cell;
    width: 25%;
    text-align: center;
    border: 1px solid #e9ebf0;
    padding-bottom: 16px;
}
.mes_point_item > div {
    background-color: #edf5f8;
    padding: 8px 0;
    font: normal normal 700 1.2rem/32px Pretendard;
    margin-bottom: 16px;
}
.mes_point_item ul {
    padding-left: 32px;
}

.mes_point_item ul li {
    text-align: left;
    height: 28px;
    font: normal normal 500 1rem/24px Pretendard;
    list-style: disc;
}

.mes_point_row {
    display: table-row;
}

@media screen and (max-width: 767px) {
    .product_coverList_cont > ul > li {
        display: table-row;
    }
}
