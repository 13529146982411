.address_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px 0;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f5f5f5;
    margin-bottom: 40px;
}
.address_cover {
    width: 300px;
    text-align: center;
}
.address_item {
    flex: 2;
}

.address_item table {
    width: 100%;
}

.address_item table tr th,
.address_item table tr td {
    padding: 15px 0;
}

.address_item table tr th {
    text-align: left;
    font: normal normal bold 18px/24px Pretendard;
    letter-spacing: 0px;
    color: #4b4b4b;
}

.address_item table tr td {
    text-align: left;
    font: normal normal normal 16px/24px Pretendard;
    letter-spacing: 0px;
    color: #4b4b4b;
}
