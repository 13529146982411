.fixBtn_container {
    position: fixed;
    right: 24px;
    bottom: 100px;
}

.fixBtn_container ul li {
    margin-bottom: 8px;
}

.fixBtn {
    width: 70px;
    height: 70px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #2c3c9a;
    border-radius: 50%;
    font: normal normal normal 14px/25px Pretendard;
    letter-spacing: 0px;
    color: #2c3c9a;
    cursor: pointer;
    line-height: 20px;
}

.toTopBtn {
    font-weight: bold;
}
